import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useLoaderData,
} from "@remix-run/react";
import stylesheet from "~/tailwind.css?url";
import type { LoaderFunctionArgs, LinksFunction } from "@remix-run/cloudflare";
import NotFound from "./routes/404";
import InternalServerError from "./routes/500";
import { json } from "@remix-run/cloudflare";
import { getCurrentEnvironment } from '~/utils/GetCurrentEnvironment';
import { EnvironmentProvider } from '~/context/EnvironmentContext';
import { getStaticImageBucketEndpoint } from '~/utils/GetStaticImageBucketEndpoint';

const isAuthorized = (request: Request, context: { cloudflare: { env: Env } }) => {
  const header = request.headers.get('Authorization');
  if (!header) return false;
  const base64 = header.replace('Basic ', '');

  // Bufferの代わりにatob関数を使用
  const decoded = atob(base64);
  const [username, password] = decoded.split(':');

  return username === context.cloudflare.env.BASIC_AUTH_ID && password === context.cloudflare.env.BASIC_AUTH_PASS
}

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const environment = context.cloudflare.env.ENVIRONMENT;
  const requiresPartialAuthEnv = 'production' //リリースの際、ここにproductionという文字列を入れる
  const requiresAuth = (environment === requiresPartialAuthEnv && url.pathname === '/upload/job-listings') || (environment !== requiresPartialAuthEnv);

  const currentDomain = url.hostname;
  const currentEnvironment = getCurrentEnvironment(currentDomain);

  if (requiresAuth) {
    if (isAuthorized(request, context)) {
      return json({authorized: true, currentEnvironment: currentEnvironment});
    } else {
      return json({authorized: false}, {status: 401, headers: {'WWW-Authenticate': 'Basic realm="Secure Area"'}});
    }
  }
  return json({authorized: true, currentEnvironment: currentEnvironment});
};

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData = useLoaderData<typeof loader>() ?? null;
  const authorized = loaderData?.authorized ?? null; // デフォルト値を false に設定

  if (authorized !== null && !authorized) {
    return <>Authorization Required</>;
  }

  const currentEnvironment = loaderData?.currentEnvironment ?? null;
  if (currentEnvironment === null) {
    return <>Current environment is null</>
  }

  const isProduction = currentEnvironment === 'production';
  const staticImageBucketEndpoint = getStaticImageBucketEndpoint(isProduction);

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        {!isProduction && (
          <>
            <meta name="robots" content="noindex, nofollow" />
          </>
        )}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {isProduction && (
          <>
            {/* Google Tag Manager */}
            <script dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-M6SCDWV2');
              `
            }} />
            {/* End Google Tag Manager */}
          </>
        )}
      </head>
      <body>
        {isProduction && (
          <>
            {/* Google Tag Manager (noscript) */}
            <noscript>
              <iframe 
                src="https://www.googletagmanager.com/ns.html?id=GTM-M6SCDWV2" 
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }} 
                title="Google Tag Manager"
              ></iframe>
            </noscript>
            {/* End Google Tag Manager (noscript) */}
          </>
        )}
        <EnvironmentProvider staticImageBucketEndpoint={staticImageBucketEndpoint}>
          {children}
        </EnvironmentProvider>
        <ScrollRestoration />
        <Scripts />
        {/* Lステップ サイト全体計測タグ */}
        {/* eslint-disable-next-line */}
        <img src='https://lstep.app/p/62139/NK3ptk' />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  const authorized =
    error.status === 401 &&
    error.data &&
    error.data.authorized === false;

  if (authorized) {
    return <div>Authorization Required</div>; // またはログインページにリダイレクト
  }

  if (error.status === 404) {
    return <NotFound />;
  }

  captureRemixErrorBoundaryError(error);

  return <InternalServerError />;
}

export const links: LinksFunction = () => [
  // NOTE: 関数外で現環境を特定する方法がぱっとわからなかったので、ハードコーディング使用
  // NOTE: 環境関係なく本番のR2を見に行くようにしている
  { rel: "apple-touch-icon", href: "https://public.fjob-kyujin.com/public/apple-touch-icon.png" },
  {
    rel: "icon",
    type: "image/png",
    sizes: "192x192",
    // NOTE: 関数外で現環境を特定する方法がぱっとわからなかったので、ハードコーディング使用
    // NOTE: 環境関係なく本番のR2を見に行くようにしている
    href: "https://public.fjob-kyujin.com/public/android-chrome-192x192.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "512x512",
    // NOTE: 関数外で現環境を特定する方法がぱっとわからなかったので、ハードコーディング使用
    // NOTE: 環境関係なく本番のR2を見に行くようにしている
    href: "https://public.fjob-kyujin.com/public/android-chrome-512x512.png",
  },
  { rel: "stylesheet", href: stylesheet },
];